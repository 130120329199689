import React from 'react';
import Head from 'next/head';
import cn from 'classnames';

import VSAnimation from './assets/VSAnimation.gif';
import paperPlane from './assets/paperPlane.svg';
import FooterPattern from '@/ui/shared/components/Landing/Sections/FooterPattern';

import BookDemoButton from '@/ui/shared/modules/ScheduleDemo/ScheduleDemoModalLegacy/BookDemoButton';
import GetStartedButton from '@/dataroom/ui/components/Landing/common/GetStartedButton';

import styles from '@/dataroom/ui/components/Landing/VeriSend/Home/home.scss';
import baseStyles from '../tenantVeriSend.scss';
import commonHomeStyles from '@/dataroom/ui/components/Landing/common/homeSection.scss';

const Home = () => (
  <div className={ styles.homeWrp }>
    <Head>
      <title>VeriSend</title>
      <meta
        name="description"
        content="A secure electronic document delivery service for capital markets."
      />
    </Head>
    <div className={ cn(styles.intro) }>
      <div className={ commonHomeStyles.introContainer }>
        <div className={ cn(commonHomeStyles.introContent, styles.introContent) }>
          <h1 className={ cn(commonHomeStyles.introTitle, baseStyles.title, styles.title) }>
            Electronic document delivery made easy
          </h1>
          <div className={ cn(commonHomeStyles.introDescription, baseStyles.description, styles.description) }>
            <p>
              VeriSend is an electronic document delivery and notarization service for capital markets. It’s secure,
              cost effective and backed by fanatical 24x7 support.
            </p>
            <p>VeriSend is free for qualified investors.</p>
          </div>
          <BookDemoButton />
          <GetStartedButton />
        </div>
        <div className={ commonHomeStyles.introAnimation }>
          <div className={ cn(commonHomeStyles.introAnimationContainer, styles.introAnimationContainer) }>
            <div className={ styles.introAnimationShadow } />
            <img src={ VSAnimation } width={ 638 } height={ 490 } />
          </div>
        </div>
      </div>
      <div className={ styles.paperPlane }>
        <img src={ paperPlane } className={ styles.paperPlane1 } />
        <img src={ paperPlane } className={ styles.paperPlane2 } />
        <img src={ paperPlane } className={ styles.paperPlane3 } />
      </div>
    </div>
    <FooterPattern />
  </div>
);

export default Home;
