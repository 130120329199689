
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/landing/verisend",
      function () {
        return require("private-next-pages/landing/verisend/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/landing/verisend"])
      });
    }
  