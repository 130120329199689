import dataroomUrl, { DataroomURL } from '@/dataroom/infrastructure/dataroomUrl';
import config from '@/dataroom/application/config/config';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import { IFooter } from '@/finsight/domain/vo/IFooter';

import styles from './footer.scss';

export const footerConfig = (tenant: DataroomTenant, pathname: string): IFooter => ({
  navigation: [
    {
      path: DataroomURL.getComplianceUrl(),
      title: 'Compliance',
      shouldOpenInNewTab: true,
    },
    {
      path: DataroomURL.getPrivacyPolicyUrl(),
      title: 'Privacy',
      shouldOpenInNewTab: true,
    },
    {
      path: DataroomURL.getSecurityPolicyUrl(),
      title: 'Security',
      shouldOpenInNewTab: true,
    },
    {
      path: DataroomURL.getTermsOfUseUrl(),
      title: 'Terms',
      shouldOpenInNewTab: true,
    },
  ],
  content: [
    {
      key: 'company',
      title: 'Company',
      collection: [
        {
          title: 'About',
          path: DataroomURL.getAboutUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'Careers',
          path: DataroomURL.getCareersUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'Contact',
          path: DataroomURL.getContactUsUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'Solutions',
          path: DataroomURL.getOurProductsUrl(),
          shouldOpenInNewTab: true,
        },
      ],
    },
    {
      key: 'products',
      title: 'Products',
      collection: [
        {
          title: 'Deal Roadshow',
          path: DataroomURL.getDrsUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'DealVDR',
          path: dataroomUrl(config.tenant.tenantDealvdr.code).getUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'Evercall',
          path: DataroomURL.getEvercallUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'Finsight.com',
          path: DataroomURL.getFinsightWebUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'InvestorSet',
          path: dataroomUrl(config.tenant.tenantInvestorSet.code).getUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'VeriSend',
          path: dataroomUrl(config.tenant.tenantVeriSend.code).getUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: '17g5.com',
          path: dataroomUrl(config.tenant.tenant17g5.code).getUrl(),
          shouldOpenInNewTab: true,
        },
      ],
    },
    {
      key: 'resources',
      title: 'Resources',
      collection: [
        {
          title: 'Log In',
          path: dataroomUrl(tenant).getLoginUrlWithCallBackToDmPortal(),
        },
        {
          title: 'Register',
          path: dataroomUrl(tenant).getRegisterUrl(
            dataroomUrl(tenant).getUrl(pathname),
          ),
        },
      ],
    },
    {
      key: 'sales',
      title: 'Sales & Support',
      collection: [
        { title: 'Americas', phoneNumber: '+1 888 997 6610 ' },
        { title: 'APAC', phoneNumber: '+852 3018 1600' },
        { title: 'EMEA', phoneNumber: '+44 80 8178 7364' },
        {
          title: config.tenant.tenantVeriSend.supportEmail,
          path: `mailto:${ config.tenant.tenantVeriSend.supportEmail }?subject=VeriSend Sales %26 Support Request`,
          linkClassName: styles.mailLink,
        },
      ],
    },
  ],
});
